/* https://www.npmjs.com/package/react-to-print */
/* @media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
} */

.print-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95%;
}

.print-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.print-text-container {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #D9D9D9;
}

.print-watermark {
  padding: 20px 0;
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    break-before: page;
    page-break-before: always;
  }
}

/* @page {
  size: auto;
  margin: 20mm;
} */